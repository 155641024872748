import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'map' ]

  connect() {
    if (typeof(google) != 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(46.227638, 2.213749),
      zoom: 5
    })

    const item = JSON.parse(this.mapTarget.dataset.item)

    this.marker = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(item['latitude'], item['longitude'])
    })
  }

}
