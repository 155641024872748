import { Controller } from "stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class extends Controller {
  static targets = [ 'map' ]

  connect() {
    if (typeof(google) != 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(46.227638, 2.213749),
      zoom: 5
    })

    const url = this.mapTarget.dataset.url

    fetch(url)
      .then(response => response.json())
      .then(data => { this.addMarkers(data) });
  }

  addMarkers(items) {
    const infoWindow = new google.maps.InfoWindow();

    var markers = [];
    var map = this.map
    items.forEach((item) => {
      const marker = new google.maps.Marker({
        position: item['coordinates'],
        map: this.map,
      })

      marker.addListener("click", () => {
        infoWindow.setContent(item['infowindow']);
        infoWindow.open(map, marker);
      });

      markers.push(marker);
    })

    var markerclusterer = new MarkerClusterer({ markers, map });

    markerclusterer.addListener("click", () => {
      infoWindow.close();
    });
    google.maps.event.addListener(map, 'click', function() {
      infoWindow.close();
    });
  }
}
