// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import Rails from "@rails/ujs"
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 500,
      onEnd: this.end.bind(this)
    })
  }

  end(event){
    if (event.newIndex == event.oldIndex) return

    let data = new FormData()
    let ids = []
    let children = document.querySelectorAll('#images [data-id]')

    for (var i = 0; i < children.length; i++) {
      ids.push(children[i].dataset.id)
    }

    data.append("ids", ids)

    Rails.ajax({
      url: this.data.get('url'),
      type: 'PATCH',
      data: data
    })
  }
}
