class loadForm {
  // This executes when the function is instantiated.
  constructor() {
    this.container = document.getElementById("form")
    this.request_form()
  }

  request_form() {
    if (!this.container) return

    let actionUrl = 'forms/' + this.container.dataset.listId + '/new';

    fetch(actionUrl, {
      method: 'GET',
      headers: {
        'X-CSRF-Token':     document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type':     'application/html',
        'Accept':           'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          this.container.innerHTML = content;
        })
      }
    });
  }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new loadForm())
